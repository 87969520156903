import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const footer = () => {
  return (
    <footer className="footer">
      <Link to="/">
        <StaticImage src="../images/logo.svg" alt="SimpleSell" />
      </Link>
      <p className="footer__text text-brand">Selling Made Simple</p>
      <div className="footer__block d-flex justify-content-between flex-wrap text-brand">
        <div>
          <a
            rel="noreferrer"
            className="footer__link text-brand"
            target="_blank"
            href="https://simplesell.com/privacy-policy/"
          >
            Privacy Policy
          </a>
          <a
            rel="noreferrer"
            className="footer__link text-brand"
            target="_blank"
            href="https://simplesell.com/terms-and-conditions/"
          >
            Terms and Conditions
          </a>
        </div>
        <div className="fl-r">
          Copyright © {new Date().getFullYear()}, SimpleSell. All Rights
          Reserved.
        </div>
      </div>
    </footer>
  )
}

export default footer
