import {
  individualCore,
  individualProf,
  individualExecutive,
} from "./individualPlans"

const teamCore = {
  priceM: 499,
  priceA: 399,
  discountM: 449,
  discountA: 359,
  contactNumber: 50,
}
const teamProf = {
  priceM: 799,
  priceA: 639,
  discountM: 749,
  discountA: 599,
  contactNumber: 100,
}
const teamExecutive = {
  priceM: 1699,
  priceA: 1359,
  discountM: 1599,
  discountA: 1279,
  contactNumber: 250,
}
console.log(individualCore)

const initialTeamPlans = [
  {
    name: "core",
    priceM: teamCore.priceM,
    priceA: teamCore.priceA,
    discountM: teamCore.discountM,
    discountA: teamCore.discountA,
    contactNumber: teamCore.contactNumber,
    individualM: individualCore.priceM,
    CRMIntegration: true,
    fiveDaysWeek: true,
    videoMeeting: "Monthly",
  },
  {
    name: "professional",
    priceM: teamProf.priceM,
    priceA: teamProf.priceA,
    discountM: teamProf.discountM,
    discountA: teamProf.discountA,
    contactNumber: teamProf.contactNumber,
    individualM: individualProf.priceM,
    CRMIntegration: true,
    fiveDaysWeek: true,
    videoMeeting: "Bi-weekly",
  },
  {
    name: "executive",
    priceM: teamExecutive.priceM,
    priceA: teamExecutive.priceA,
    discountM: teamExecutive.discountM,
    discountA: teamExecutive.discountA,
    contactNumber: teamExecutive.contactNumber,
    individualM: individualExecutive.priceM,
    CRMIntegration: true,
    sevenDaysWeek: true,
    videoMeeting: "Weekly",
    phoneSupport: true,
  },
]

export const teamPlans = initialTeamPlans.map(plan => {
  return {
    ...plan,
    pricePerContactA: (plan.priceA / plan.contactNumber).toFixed(2),
    pricePerContactM: (plan.priceM / plan.contactNumber).toFixed(2),
    discountPerContactA: (plan.discountA / plan.contactNumber).toFixed(2),
    discountPerContactM: (plan.discountM / plan.contactNumber).toFixed(2),
    saveTeamM: plan.individualM - plan.priceM,
    saveTeamA: plan.individualM - plan.priceA,
    saveTeamDiscountM: plan.individualM - plan.discountM,
    saveTeamDiscountA: plan.individualM - plan.discountA,
  }
})
