import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const listTrial = [
  {
    icon: <StaticImage src="../images/checkIcon.svg" alt="SimpleSell" />,
    title: "20 contacts or company profiles",
  },
  {
    icon: <StaticImage src="../images/checkIcon.svg" alt="SimpleSell" />,
    title: "CRM Integration capability",
  },
  {
    icon: <StaticImage src="../images/checkIcon.svg" alt="SimpleSell" />,
    title: "SMS messaging and platform chat with live Sales Assistant",
  },
  {
    icon: <StaticImage src="../images/checkIcon.svg" alt="SimpleSell" />,
    title: "Available 9 a.m. - 5 p.m. (MT) 5 days a week",
  },
  {
    icon: <StaticImage src="../images/checkIcon.svg" alt="SimpleSell" />,
    title: "1 month trial",
  },
]

const InformationBlock = () => {
  return (
    <div className="position-relative d-grid backgroundImage">
      <StaticImage
        layout="fullWidth"
        aspectRatio={3 / 1}
        src="../images/backgroundHeader.svg"
        alt="SimpleSell"
        className="gridArea-1"
      />
      <div className="d-grid gridArea-1 position-relative place-items-center">
        <div className="position-absolute information-block">
          <h1 className="h1 information-block__main-title bold text-white">
            Start Your Free Trial
          </h1>
          <div className="information-block__description bold text-white">
            Priceless. In more than one way.
          </div>
          <ul className="information-block__list">
            {listTrial.map((item, key) => (
              <li
                className="information-block__item text-white text-regular d-flex align-items-center"
                key={key}
              >
                <span className="information-block__icon">{item.icon}</span>
                <span className="information-block__title">{item.title}</span>
              </li>
            ))}
          </ul>
          <button className="information-block__button information-block__button__hover pointer bg-rose text-white text-bolder">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.simplesell.com/signup"
            >
              Get Started
            </a>
          </button>
        </div>
      </div>

      <div className="position-absolute trial-image">
        <StaticImage src="../images/trialImage.svg" alt="SimpleSell" />
      </div>
    </div>
  )
}

export default InformationBlock
