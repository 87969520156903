import React from "react"
import { createCSSClass } from "../utils/jsxHelpers"

const Input = ({ onChange, onBlur, value }) => {
  return (
    <div className={createCSSClass(["d-flex align-items-center range__block"])}>
      <div className="text-bold range__title">How many users do you have?</div>
      <input
        className="text-bold bg-light range__input"
        name="teamCount"
        value={value}
        /*onChange={e => {
          const value = e.target.value.replace(/([^0-9])+/i, "")
          setTeamCount(value)
        }}*/
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  )
}

export default Input
