export const individualCore = {
  priceM: 599,
  priceA: 479,
  contactNumber: 50,
}
export const individualProf = {
  priceM: 899,
  priceA: 719,
  contactNumber: 100,
}
export const individualExecutive = {
  priceM: 1799,
  priceA: 1439,
  contactNumber: 250,
}

const initialIndividualPlans = [
  {
    name: "core",
    priceM: individualCore.priceM,
    priceA: individualCore.priceA,
    contactNumber: individualCore.contactNumber,
    CRMIntegration: true,
    fiveDaysWeek: true,
    videoMeeting: "Monthly",
  },
  {
    name: "professional",
    priceM: individualProf.priceM,
    priceA: individualProf.priceA,
    contactNumber: individualProf.contactNumber,
    CRMIntegration: true,
    fiveDaysWeek: true,
    videoMeeting: "Bi-weekly",
  },
  {
    name: "executive",
    priceM: individualExecutive.priceM,
    priceA: individualExecutive.priceA,
    contactNumber: individualExecutive.contactNumber,
    CRMIntegration: true,
    sevenDaysWeek: true,
    videoMeeting: "Weekly",
    phoneSupport: true,
  },
]

export const individualPlans = initialIndividualPlans.map(plan => {
  return {
    ...plan,
    pricePerContactA: (plan.priceA / plan.contactNumber).toFixed(2),
    pricePerContactM: (plan.priceM / plan.contactNumber).toFixed(2),
    saveIndividual: plan.priceM - plan.priceA,
  }
})
