import React from "react"
import { createCSSClass } from "../utils/jsxHelpers"

const RangePrice = ({
  teamCount,
  setTeamCount,
  tabIndex,
  isAnnual,
  setIsAnnual,
}) => {
  return (
    <div
      className="d-flex flex-wrap align-items-center flex-grow-1 button__block"
      style={{ paddingLeft: 8, paddingRight: 8 }}
    >
      <span className="mr-1 text-bolder biling-title">Billing Cycle</span>
      <div
        className={createCSSClass(
          ["rounded-8 position-relative bg-light button__wrapper"],
          {
            "is-annual": isAnnual === true,
          }
        )}
      >
        <button
          className={createCSSClass(
            ["btn button__width text-bolder cursor-pointer"],
            {
              "text-white": !isAnnual === true,
              "text-primary": isAnnual === false,
            }
          )}
          onClick={() => setIsAnnual(!isAnnual)}
        >
          Monthly
        </button>
        <button
          className={createCSSClass(
            ["btn button__width text-bolder cursor-pointer"],
            {
              "text-white": isAnnual === true,
              "text-primary": isAnnual === false,
            }
          )}
          onClick={() => setIsAnnual(!isAnnual)}
        >
          Annual (Save 20%)
        </button>
      </div>
    </div>
  )
}

export default RangePrice
