import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useState } from "react"
import Icon from "./icon"

const Header = () => {
  const [openedDropdown, setOpenedDropdown] = useState(false)

  const handleDropdownToggle = () => {
    setOpenedDropdown(!openedDropdown)
  }

  return (
    <header className="bg-white w-100 position-fixed header">
      <div className="d-flex align-items-center p-xs header__container">
        <a
          rel="noreferrer"
          className="menu__link"
          href="https://simplesell.com"
        >
          <StaticImage src="../images/logo.svg" alt="SimpleSell" />
        </a>

        <button className="show-mobile" onClick={handleDropdownToggle}>
          <Icon color="#2D3037" size={24} icon="burger" />
        </button>

        <nav
          className={`main-menu ml-auto ${
            openedDropdown ? "opened" : "desktop"
          }`}
        >
          <ul className={`menu `}>
            <li className="menu__item">
              <a
                rel="noreferrer"
                className="menu__link"
                href="https://simplesell.com/faq/"
              >
                FAQ
              </a>
            </li>
            <li className="menu__item">
              <Link to="/" className="menu__link">
                Pricing
              </Link>
            </li>

            <li className="menu__item">
              <a
                rel="noreferrer"
                className="menu__link"
                href="https://simplesell.com/resources/"
              >
                Resources
              </a>
            </li>
            <li className="menu__item">
              <a
                rel="noreferrer"
                className="menu__link"
                href="https://simplesell.com/contact/"
              >
                Contact us
              </a>
            </li>
            <li className="menu__item">
              <a
                href="https://app.simplesell.com/login"
                target="_blank"
                rel="noreferrer"
                className="menu__link"
              >
                Login
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
