import React, { useState } from "react"
import Slider from "react-slick"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Plan from "../components/plan"
import RangePrice from "./rangePrice"
import { individualPlans } from "../plans/individualPlans"
import { teamPlans } from "../plans/teamPlans"
import Icon from "./icon"
import "react-tabs/style/react-tabs.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Input from "./Input"

const PriceList = () => {
  const [tabIndex, setTabIndex] = useState(0)
  const [userCount, setUserCount] = useState(2)
  const [isAnnual, setIsAnnual] = useState(false)
  const tab = ["INDIVIDUAL", "TEAM"]

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  }

  const handleChange = e => {
    const { value } = e.target

    if (value >= 0 && value % 1 === 0 && !value.includes(".")) {
      setUserCount(value)
    }
  }

  const handleBlur = e => {
    const { value } = e.target

    if (value <= 2) {
      setUserCount(2)
    }
  }

  const handleSelect = index => {
    setTabIndex(index)
  }

  const handleSwipe = () => {
    document.activeElement.blur()
  }

  return (
    <>
      <div className="tab__list">
        <Tabs selectedIndex={tabIndex} onSelect={handleSelect}>
          <TabList>
            <Tab>
              <Icon color="secondary" size={24} icon="individual" />
              Individual Pricing
            </Tab>
            <Tab>
              <Icon color="secondary" size={24} icon="team" />
              Team Pricing
            </Tab>
          </TabList>

          {/*individual pricing*/}
          <TabPanel>
            <RangePrice
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              isAnnual={isAnnual}
              setIsAnnual={setIsAnnual}
            />

            <Slider {...settings}>
              {individualPlans.map(plan => (
                <Plan
                  key={plan.name}
                  tab={tab[tabIndex]}
                  plan={plan}
                  isAnnual={isAnnual}
                  className="price__item__individual"
                />
              ))}
            </Slider>
          </TabPanel>

          {/*team pricing*/}
          <TabPanel>
            <div className="d-flex flex-wrap align-items-center flex-grow-1">
              <RangePrice
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                isAnnual={isAnnual}
                setIsAnnual={setIsAnnual}
              />
              <Input
                value={userCount}
                min={2}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <Slider {...settings} onSwipe={handleSwipe}>
              {teamPlans.map(plan => (
                <Plan
                  key={plan.name}
                  tab={tab[tabIndex]}
                  plan={plan}
                  isAnnual={isAnnual}
                  className="price__item__team"
                  userCount={userCount}
                />
              ))}
            </Slider>
          </TabPanel>
        </Tabs>
      </div>
    </>
  )
}

export default PriceList
